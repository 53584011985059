<template>
	<div style="display: flex;flex-direction: column;justify-content: center; " class="font-famliy">
		<div class="zxyj-content-box" >
			<div class="banner-content">
				<div class="banner">
						<img style="height: 400px;" src="@/assets/images/banner/about.png" alt="加载失败" />
				</div>
			</div>
		</div>
		<div style="display: flex;flex-direction: column;justify-content: center; align-items: center;">
		<div style="display: flex; flex-direction: column;width: 1152px;background-color: white; margin-top: 10px;">
			<div style="display: flex;align-items: flex-start;margin-top: 20px;width: 1152px;">
				<div style="display: flex;align-items: flex-start;">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item :to="{ path: '/' }" style="font-family: MicrosoftYaHei;">首页</el-breadcrumb-item>
						<el-breadcrumb-item style="font-family: MicrosoftYaHei;">{{route.meta.title}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<div style="display: flex;flex-direction: column;justify-content: space-around;margin-top: 50px;">
				<div style="display:flex ;flex-direction: column;align-items: center;justify-content: space-around;">
					<div class="about_title" style="display: flex;">鑫昇腾概况</div>
					<div class="about_broder" style="display: flex;margin-top: 10px;"></div>
				</div>
				<div style="display: flex;flex-direction: column;justify-content: space-between; height: 320px;margin-top: 50px;">
					<div class="font_clz" >
						<p>江苏鑫昇腾科技发展股份有限公司（www.ixzyj.com）（以下简称“鑫昇腾”）成立于2019年9月，总部位于江苏·南京，公司纵深于智能爬架行业，立志成为中国智能爬架行业创新领导者，成立2年已搭建了遍布全国的销售和服务网络，在行业内已初步具备规模优势，公司为客户提供智能爬架租赁、销售、运营、监管、技术服务等一站式解决方案和服务保障，通过优质的服务、良好的口碑，先后获得了绿地集团、碧桂园、中国铁建、中交集团、中建集团、中天建设、江苏省建等多家大中型房地产开发企业及建筑施工单位的青睐和认可。</p>
					</div>
					<div class="font_clz" >
						<p>鑫昇腾自主研发、倾力打造的“鑫筑云建”智能爬架运营服务平台，是国内首家以爬架运营服务为核心的物联平台，实现大数据、APP、物联网的完美融合，平台依托先进的物联技术、大数据分析技术、智能化传感设备，打造新型智能爬架生态和智能化施工场景，通过规范化项目施工标准、设计服务标准、安全管控标准，为千亿级产能的智能爬架行业升级赋能，将爬架资产数字化，通过高效流转，为用户提供智能爬架销售租赁、仓储物流、翻新维护、技术支持、智能监控等综合运营服务，帮助用户降低30-40%的租赁成本和翻新运输成本。</p>
					</div>
					<div class="font_clz">
						<p>鑫昇腾打造的智能化建筑制造研发中心，围绕智慧工地、智能机器人制造、物联网技术探索与优化，实现多网融合，多元化平台建设。</p>
					</div>
					<div class="font_clz">
						<p>未来五年，鑫昇腾将在全国范围内设置至少5个翻新服务基地，并逐步探索拓展工程服务品类如机器人在建筑施工领域的应用等，全面推进平台战略，拓展多元营收模式，开启智能数字化运营服务模式，致力于引领并推动行业信息化、智能化发展，实现行业变革。</p>
					</div>
				</div>
			</div>
			<div style="display: flex;flex-direction: column;justify-content: space-around; margin-top: 100px;width: 1152px;">
				<div style="display:flex ;flex-direction: column;align-items: center;justify-content: space-around;">
					<div class="about_title" style="display: flex;">企业文化</div>
					<div class="about_broder" style="display: flex;margin-top: 10px;margin-left: -35px; "></div>
				</div>
				<div style="display: flex; margin-top: 30px;align-items: center;">
				<div style="display: flex;width: 1152px;height: 180px;align-items: center;justify-content: center;" class="companyClaz">
					<div style="width: 698px;height: 48px;font-size: 36px;font-family: MicrosoftYaHei;color: #FFFFFF;line-height: 48px;display: flex;">中国智能爬架智慧创新领导企业</div>
				
				</div>
				</div>
				<div style="display: flex;flex-direction: row;flex-wrap:wrap;justify-content: space-between;">
					<div class="itemClzBox">
						<div class="itemClz" style="display: flex;flex-direction: column;">
							<div class="itemTitle" style="display: flex;">
								企业愿景
							</div>
							<div class="itemSub" style="display: flex;">
								让建筑更智能、让城市更文明，让世界更美好
							</div>
						</div>
						<div style="display: flex;">
							<img src="../../assets/image/about/aboutus-企业愿景-img备份.png" />
						</div>
					</div>
					<div class="itemClzBox">
						<div class="itemClz" style="display: flex;flex-direction: column;">
							<div class="itemTitle" style="display: flex;">
								企业使命
							</div>
							<div class="itemSub" style="display: flex;">
								为员工搭建成长的平台，为客户提供卓越的服务
							</div>
						</div>
						<div style="display: flex;">
							<img src="../../assets/image/about/aboutus-企业使命-img备份.png" />
						</div>
					</div>
					<div class="itemClzBox">
						<div class="itemClz" style="display: flex;flex-direction: column;">
							<div class="itemTitle" style="display: flex;">
								企业理念
							</div>
							<div class="itemSub" style="display: flex;">
								诚信为本、服务至上
							</div>
						</div>
						<div style="display: flex;">
							<img src="../../assets/image/about/aboutus-企业理念-img备份.png" />
						</div>
					</div>
					<div class="itemClzBox">
						<div class="itemClz" style="display: flex;flex-direction: column;">
							<div class="itemTitle" style="display: flex;">
								企业精神
							</div>
							<div class="itemSub" style="display: flex;">
								勤奋、感恩、严谨、创新
							</div>
						</div>
						<div style="display: flex;">
							<img src="../../assets/image/about/aboutus-企业精神-img备份.png" />
						</div>
					</div>
					<div class="itemClzBox">
						<div class="itemClz" style="display: flex;flex-direction: column;">
							<div class="itemTitle" style="display: flex;">
								合作观
							</div>
							<div class="itemSub" style="display: flex;">
								信任创造奇迹，精诚合作，携手共赢。
							</div>
						</div>
						<div style="display: flex;">
							<img src="../../assets/image/about/aboutus-合作观-img.png" />
						</div>
					</div>
					<div class="itemClzBox">
						<div class="itemClz" style="display: flex;flex-direction: column;">
							<div class="itemTitle" style="display: flex;">
								价值观
							</div>
							<div class="itemSub" style="display: flex;">
								以人为本、知行合一
							</div>
						</div>
						<div style="display: flex;">
							<img src="../../assets/image/about/aboutus-价值观-img.png" />
						</div>
					</div>
					
				</div>
			</div>
			
			<div style="display: flex;flex-direction: column;justify-content: space-around; margin-top: 100px;background-color: white;">
				<div style="display:flex ;flex-direction: column;align-items: center;justify-content: space-around;">
					<div class="about_title" style="display: flex;">资质证书</div>
					<div class="about_broder" style="display: flex; margin-top: 10px;margin-left: -35px;"></div>
				</div>
				<div style="display: flex; margin-top: 30px;">
				<img src="../../assets/image/about/aboutus-corporatelicense-img@2x.png" />
				</div>
				
				</div>
				<div style="display: flex;flex-direction: column;justify-content: space-around; margin-top: 100px;background-color: white;align-items: center;">
					<div style="display:flex ;flex-direction: column;align-items: center;justify-content: space-around;">
						<div class="about_title" style="display: flex;">联系我们</div>
						<div class="about_broder" style="display: flex; margin-top: 10px;margin-left: -35px;"></div>
					</div>
					<div style="display: flex;font-size: 24px;justify-content:center;flex-direction: column;align-items: center;margin-top: 50px;">
						<p>生产安全耐用的附着式升降脚手架是我们一项的宗旨</p>
						<p>用户的满意就是我们的品牌</p>
					</div>
					<div style="width: 1152px;height: 1px;background: #E6E6E6;display: flex;margin-top: 60px;"></div>
					<div style="display: flex;flex-direction: row;justify-content: space-around;">
						<div style="display: flex; flex-direction: column;justify-content: space-around;margin-right: 80px;">
							<div style="display: flex;flex-direction: row;align-items: center;" class="footClz"><img style="width: 16px;height: 16px;margin-right: 5px;" src="../../assets/image/about/aboutus-ico-suggestion.png" />意见反馈</div>
							<div style="display: flex;"><textarea style="resize:none; width: 250px;height: 78px;border-radius: 2px;border: 1px solid #DDDDDD;padding:12px;" type="text" placeholder="请输入留言内容"/></div>
							<div style="display: flex;"><input style="width: 250px;height: 40px;border-radius: 2px;border: 1px solid #DDDDDD;padding:12px;" type="text" placeholder="请输入姓名"/></div>
							<div style="display: flex;"><input style="width: 250px;height: 40px;border-radius: 2px;border: 1px solid #DDDDDD;padding:12px;" type="text" placeholder="请输入手机号"/></div>
							
							<div style="display: flex;margin-left: 60px;"><button style="width: 126px;height: 32px;background: #112A88;border-radius: 2px;color: white;border:0px;cursor: pointer;" > 提交</button></div>
							
						</div>
						<div style="width: 1px;height: 387px;background: #E6E6E6;display: flex;"></div>
						<div style="display: flex;flex-direction: column;justify-content: space-around; margin-left: 50px;height: 330px;">
							<div style="display: flex;flex-direction: row;align-items: center;" class="footClz"><img style="width: 16px;height: 16px;margin-right: 5px;" src="../../assets/image/about/aboutus-ico-contactstyle.png" />联系方式</div>
							<div style="display: flex;">电话：177-2154-7063</div>
							<div style="display: flex;" >邮箱：40358771@qq.com</div>
							<div style="display: flex;" class="siteClz">网址：<a href="http://www.ixzyj.com/" onclick="window.open(this.href);return false"><span style="color: #4A90E2;">http://www.ixzyj.com/</span></a></div>
							<div style="display: flex;">地址：南京市江宁区胜利路99号名家科技大厦A座1102室（江宁开发区）</div>
						</div>
					</div>
					<div style="width: 1152px;height: 1px;background: #E6E6E6;display: flex;margin-bottom: 100px;"></div>
					</div>
		</div>
		</div>
	</div>
</template>

<script>
	import http from "@/common/utils/Http";

	export default {
		metaInfo: {
			title: "鑫昇腾公司介绍",
			meta:[
				{name:"keywords", content: "爬架,建筑爬架,智能爬架,全钢爬架,鑫昇腾"},
				{name:"description", content: "江苏鑫昇腾科技发展股份有限公司专注于建筑爬架的设计、制造和专业承包,提供爬架租赁、销售、运营、安全监管、技术服务等一站式解决方案。"}
			]
		},
		data() {
			return {
				banner: [],
				route: this.$route,
				elCarousel: "400px",
				
			}
		},
		created() {
			this.getBanner();
		},
		methods: {// 提交留言
		
			getBanner() {
				http.api.get("/site/banner/list").then((res) => {
					this.banner = res.data.rows;
				});
			},
		}
	};
</script>

<style lang="scss" scoped>
	.font-famliy  {
		div,a,span {
			font-family: MicrosoftYaHei;
		}
	}
	.companyClaz{
		background-image: url(../../assets/image/about/aboutus-corporateculture-bg.png);
	}
	.footClz{
		
		height: 21px;
		font-size: 16px;
		font-family: MicrosoftYaHeiSemibold;
		color: #333333;
		line-height: 21px;
		letter-spacing: 1px;
	}
	.siteClz{
		width: 256px;
		height: 19px;
		font-size: 14px;
		font-family: MicrosoftYaHei;
		color: #333333;
		line-height: 19px;
		letter-spacing: 1px;
	}
	.el-carousel__item h3 {
	    color: #475669;
	    font-size: 14px;
	    opacity: 0.75;
	    line-height: 200px;
	    margin: 0;
	  }
	  
	  .el-carousel__item:nth-child(2n) {
	    background-color: #99a9bf;
	  }
	  
	  .el-carousel__item:nth-child(2n+1) {
	    background-color: #d3dce6;
	  }
	img{
		object-fit: none;
	}
	.itemSub{
		width: 300px;
		height: 19px;
		font-size: 14px;
		font-family: MicrosoftYaHei;
		color: #333333;
		line-height: 19px;
	}
	.itemTitle{
		width: 80px;
		height: 27px;
		font-size: 20px;
		font-family: MicrosoftYaHei;
		color: #112A88;
		line-height: 27px;
	}
	.itemClzBox{
		width: 368px;
		height: 363px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-shadow: 0px 4px 10px 0px rgba(0, 15, 72, 0.6);
		// border:1px dashed #000000;
		margin-top: 24px;
	}
	.itemClzBox img{height: 270px;}
	.itemClz{
		
		justify-content: center;
		width: 340px;
		height: 92px;
		background: #FFFFFF;
		border-radius: 2px 2px 0px 0px;
		margin-left: 20px;
	}
	.font_clz{
		font-size: 14px;
		font-family: MicrosoftYaHei;
		color: #333333;
		line-height: 22px;
		
	}
	.about_title{
		width: 180px;
		height: 48px;
		font-size: 36px;
		font-family: MicrosoftYaHeiSemibold;
		color: #333333;
		line-height: 48px;
	}
	.about_broder{
		width: 73px;
		height: 2px;
		background: #112A88;
	}
	.banner-content {
		margin-top: 80px;
		position: relative;
		height: 400px;
		width: 100%;
	}

	.banner {
		height: 100%;
		width: 100% !important;
		overflow: hidden;
		text-align: center;
		margin: 0 auto;
		padding: 0;
		z-index: 1;
	}

	.about1 {

		font-size: 0.85rem;
		display: flex;
		justify-content: space-between;
		color: #363636;

		.lf {
			width: 60%;

			.suba {
				margin-bottom: 4rem;
				letter-spacing: 0.5px;
				line-height: 1.4rem;
			}

			.subb {
				text-indent: 2rem;
				letter-spacing: 0.5px;
				line-height: 1.4rem;
			}
		}

		.rt {
			width: 35%;

			p {
				margin-bottom: 1.7rem;
				letter-spacing: 0.5px;
			}
		}
	}

	.about2 {
		text-align: center;
		margin: 6rem 0 0;

		.li {
			display: flex;
			margin: 0 auto;
			max-width: 55rem;
			margin-bottom: 2rem;

			.block {
				width: 25%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				.sub1 {
					width: 7.5rem;
					font-size: .75rem;
					padding: .7rem;
					border: 1px solid #F1F1F1;
					background: #FCFCFC;
				}

				.sub2 {
					width: .5rem;
				}
			}
		}
	}

	// 标题字体
	.title {
		margin-bottom: 2rem;

		.cn {
			font-size: 1.4rem;
		}

		.en {
			font-size: 1.15rem;
			color: #363636;
		}
	}

	// 联系我们
	.contact {
		text-align: center;
		background: #fff;
		margin: -0.5rem 0;
		padding: 4rem 0;

		.text {
			font-size: 1.3rem;
			margin: 2rem 0;
			color: #363636;
		}

		.form {
			display: flex;
			border: 1px solid #b5b5b5;

			.form_lf {
				width: 50%;
				border-right: 1px solid #b5b5b5;

				.img_form1 {
					width: 2rem;
					margin: 2rem auto 0;
				}

				.submit {
					width: 50%;
					display: flex;
					margin: 0 auto;
					flex-direction: column;

					textarea {
						padding: 0.5rem;
						margin: 1rem 0;
						border: 1px solid #b5b5b5;
					}

					input {
						padding: 0.5rem;
						margin: 1rem 0;
						border: 1px solid #b5b5b5;
					}

					button {
						width: 7rem;
						margin: 1rem auto 2rem;
						border: unset;
						padding: 0.5rem 0;
						background: #145ab2;
						color: #fff;
					}
				}
			}

			.form_rt {
				width: 50%;

				.img_form2 {
					width: 2rem;
					margin: 2rem auto;
				}

				p {
					font-size: 1rem;
					color: #363636;
					line-height: 3rem;
				}

				button {
					width: 7rem;
					margin: 1rem auto 2rem;
					border: 1px solid #000;
					padding: 0.5rem 0;
					background: #fff;
					color: #000;
				}
			}
		}
	}

	// 主要客户
	.key {
		padding: 2rem;
		margin: 2rem 0 8rem;

		.title {
			text-align: center;
		}

		.customers {
			.li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 3rem;

				.logo {
					width: 15%;
					background: #fff;
					padding: 1rem;

					.image {
						border: 1px dashed #000;

						img {
							width: 100%;
						}
					}
				}
			}
		}
	}
</style>
